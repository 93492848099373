+function($) {
  'use strict';

  String.prototype.trunc = String.prototype.trunc ||
    function(n) {
      return this.length > n ? this.substr(0, n - 1) + '&hellip;' : this;
    };

  var Upload = function(element, options) {
    this.$element = $(element);
    this.options = $.extend({}, Upload.DEFAULTS, options);
    this.$store = this.$element.closest('form').find('[name="' + this.options.name + '"]');
    this.buildHtml();
    this.bindEvents();
    this.fillFiles();
    this.uploading = 0;
  }

  Upload.VERSION = '1.0.0';

  Upload.ITEM_TEMPLATE = _.template(
    '<div class="kartus-upload-item">&nbsp;' +
    '<div class="kartus-upload-progress"><div class="progress-bar progress-bar-primary progress-bar-striped"></div></div>' +
    '<div class="kartus-upload-item-content">' +
    '<span class="kartus-upload-delete float-right"><i class="fa fa-times"></i></span>' +
    '<i class="fa fa-fw fa-paperclip"></i>&nbsp;' +
    '<%= name %>' +
    '</div>' +
    '</div>'
  );

  Upload.DEFAULTS = {
    name: '_files',
    uploadingText: 'Nahrávám přílohy ...',
    fileuploadOptions: {
      container: null,
      autoUpload: false,
      dataType: 'json',
      type: 'POST',
      formData: {}
      ,
    }
  };

  Upload.prototype.buildHtml = function() {
    this.$input = $('<input type="file" />')
      .attr('multiple', false)
      .hide();

    var container = this.$element.parent();
    if (this.options.container) {
      container = $(this.options.container);
    }

    this.$wrapper = $('<div />')
      .append(this.$input)
      .addClass('kartus-uploads')
      .appendTo(container);
    this.$input.fileupload(this.fileuploadOptions())
  };

  Upload.prototype.open = function() {
    this.$input.click();
  };

  Upload.prototype.fileuploadOptions = function() {
    return $.extend({}, this.options.fileuploadOptions, {
      url: (this.options.url || null)
    });
  };

  Upload.prototype.bindEvents = function() {

    this.$element.on('click.kartus.upload', function(e) {
      e.preventDefault();
      this.$wrapper.find('input[type=file]').trigger('click');
    }.bind(this));

    this.$input.on('fileuploadadd', function(e, data) {
      this.uploading += 1;
      this.add(data);
      this.toggleForm();
    }.bind(this));

    this.$input.on('fileuploadprogress', function(e, data) {
      this.progress(data);
    }.bind(this));

    this.$input.on('fileuploadfail', function(e, data) {
      this.fail(data);
    }.bind(this));

    this.$input.on('fileuploaddone', function(e, data) {
      this.done(data);
    }.bind(this));

    this.$input.on('fileuploadalways', function(e, data) {
      this.uploading -= 1;
      this.toggleForm();
    }.bind(this));
  };

  Upload.prototype.fillFiles = function() {
    try {
      this.files = JSON.parse(this.$store.val());
    } catch (e) {
      this.files = {};
    }

    for (var id in this.files) {
      var file = this.files[id],
          html = $(Upload.ITEM_TEMPLATE({name: file.name.trunc(40)})).addClass('done');
      this.$wrapper.append(html);
      html.find('.kartus-upload-delete').one('click', function() {
        html.remove();
        delete this.files[file.id];
        $.ajax(file.delete);
        this.storeFiles();
      }.bind(this));
    }
  }

  Upload.prototype.add = function(data) {
    data.context = $(Upload.ITEM_TEMPLATE({name: data.files[0].name.trunc(40)}));

    this.$wrapper.append(data.context);
    data.submit();
  };

  Upload.prototype.progress = function(data) {
    data.context.find('.kartus-upload-progress div')
      .css('width', data.loaded / data.total * 100 + '%');
  };

  Upload.prototype.done = function(data) {
    var file = data.result[0];
    data.context.find('.kartus-upload-delete').one('click', function() {
      data.context.remove();
      delete this.files[file.id];
      $.ajax({
          url: data.result[0].delete,
          type:'delete'
      });
      this.storeFiles();
    }.bind(this));
    this.files[file.id] = file;
    this.storeFiles();
    data.context.addClass('done');
  };

  Upload.prototype.toggleForm = function() {
    var button = this.$element.closest('form').find('input[type=submit]');
    if (this.uploading == 0) {
      button.attr('disabled', false).text(this.oldButton);
      this.oldButton = null;

    } else {
      if (!this.oldButton) {
        this.oldButton = button.text();
      }
      button.attr('disabled', true).text(this.options.uploadingText);
    }
  }

  Upload.prototype.storeFiles = function() {
    this.$store.val(JSON.stringify(this.files));
  };

  Upload.prototype.fail = function(data) {
    data.context.addClass('fail');
    data.context.find('.kartus-upload-delete').one('click', function() {
      data.context.remove();
    });
  };

  function Plugin(option) {
    return this.each(function() {
      var $this   = $(this),
          data    = $this.data('kartus.upload'),
          options = typeof option == 'object' && option;
      if (!data) $this.data('kartus.upload', (data = new Upload(this, options)));
      if (typeof option == 'string') data[option]()
    });
  };

  var old = $.fn.multiupload;

  $.fn.multiupload = Plugin;
  $.fn.multiupload.Constructor = Upload;

  $.fn.multiupload.noConflict = function() {
    $.fn.multiupload = old;
    return this;
  }

  $(window).on('load page:load pjax:success ajax:success ajax:new-content', function() {
    $('[data-multiupload]').each(function(e) {
      var $el  = $(this),
          data = $el.data('multiupload');
      Plugin.call($el, data);
    });
  });
}
(jQuery);