$(document).on('ajax:complete', 'form[data-remote]', function(e, result) {
    var form = $(this);
    form.find('.form-group.has-error').each(function() {
        $(this).removeClass('has-error');
        $(this).find('p.help-block[data-js]').remove();
    });
    if (result.status == 422) {
        var errors = result.responseJSON;
        for (var name in errors) {
            var parts     = name.split('.'),
                arrayName = '';
            parts.forEach(function(part) {
                arrayName += (arrayName == '') ? part : ('[' + part + ']')
            });
            var input = form.find('[name="' + arrayName + '"]');
            input.closest('.form-group')
                .addClass('has-error')
                .append($('<p class="help-block" data-js="1" />').text(errors[name][0]));
        }
        var $el = form.find('.has-error .form-control:first');
        if ($el.attr('data-editor')) {
            $el.ckeditor().editor.focus();
        } else {
            $el.focus();
        }
    }
});

// Přetížení rails confirmation
$.rails.allowAction = function(link) {
    if (link.data("confirm") == undefined) {
        return true;
    }
    $.rails.showConfirmationDialog(link);
    return false;
}

$.rails.confirmed = function(link) {
    link.data("confirm", null);
    link.trigger("click.rails");
}

$.rails.showConfirmationDialog = function(link) {
    var message = link.data("confirm").split('|'),
        $modal  = $('#__confirmation');
    $modal.find('.modal-title').text(message[0]);
    $modal.find('.modal-body').text(message[1]);
    $modal.find('[data-confirmation]').one('click', function() {
        $.rails.confirmed(link);
    });
    $modal.modal('show');

};

// Ajaxizace
var handleAjax = function(payload) {
    if (!payload.content) {
        return;
    }

    payload.content = String(payload.content)
        .replace(/<\!DOCTYPE[^>]*>/i, '')
        .replace(/<(html|head|body|title|meta)([\s\>])/gi, '<div class="document-$1"$2')
        .replace(/<\/(html|head|body|title|meta)\>/gi, '</div>');

    payload.content = $(payload.content);

    if (payload.redirect) {
        window.location = payload.redirect;
    }

    if (payload.reload) {
        console.log('yeaaa');
        if ($.support.pjax) {
            $.pjax.reload('#content', {scrollTo: false})
        } else {
            window.location.reload();
        }
    }

    if (payload.follow) {
        $.ajax(payload.follow, {
            success: function(data) {
                payload.content = data.content;
                payload.follow = null;
                handleAjax(payload);
            }
        });
        return;
    }

    if (payload.remove) {
        payload.remove.forEach(function(selector) {
            $(selector).remove();
        });
    }

    if (payload.append) {
        payload.append.forEach(function(selectors) {
            var $what  = payload.content.find('#' + selectors[0]),
                $where = $('#' + selectors[1]);
            $what.appendTo($where);
            $what.find('form [autofocus]').focus();
        });
        $(document).trigger('ajax:new-content');
    }

    if (payload.scrollTo) {
        $.scrollTo(payload.scrollTo, {duration: 500, offset: {top: -50, left: 0}});
    }

    if (payload.replace) {
        NProgress.done();
        payload.replace.forEach(function(selector) {
            selector = '#' + selector;
            $(selector).html(payload.content.find(selector).html());
        });
        $(document).trigger('ajax:new-content');
    }

    if (payload.tab) {
        payload.tab.forEach(function(selector) {
            selector = '#' + selector;
            $(selector).tab('show');
        });
    }

    if (payload.eval) {
        payload.eval.forEach(function(js) {
            eval(js);
        });
    }

    if (payload.closeModal) {
        $('#__modal').modal('hide');
    }

    if (payload.modal) {
        var $modal = $('#__modal'),
            $data  = payload.content.find('#' + payload.modal);

        // Naplní data
        var formToRemove = $modal.find('form');
        if (formToRemove.length > 0) {
            $modal.find('.modal-content').html(formToRemove.html());
        }

        $modal.find('.modal-title')
            .html($data.find('[data-modal=title]').html());
        $modal.find('.modal-body')
            .html($data.find('[data-modal=body]').html())
            .find('[data-modal=buttons]')
            .remove();
        $modal.find('[data-modal=buttons]')
            .html('')
            .html($data.find('[data-modal=buttons]').html());
        $modal.find('.modal-dialog').attr('class', 'modal-dialog');
        $modal.find('.modal-dialog').addClass($data.attr('data-modal-class'));

        // Přesunutí formu
        var form = $modal.find('form')
        if (form.length > 0) {
            var body = $modal.find('.modal-content');
            body.html(form.html(body.html()));
            $modal.one('shown.bs.modal', function() {
                form.find('[autofocus]').focus();
            });
        }

        $modal.modal('show');
        $(document).trigger('ajax:new-content');
    }
};

$(document).on('ajax:success', function(e, payload, status, xhr) {
    handleAjax(payload);
});